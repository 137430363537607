/**
 * @format
 */

export class AizawaAttractor {
  static #a = 0.95;
  static #b = 0.7;
  static #c = 0.6;
  static #d = 3.5;
  static #e = 0.25;
  static #f = 0.1;

  static #speed = 1;

  static #scale = 1;

  constructor() {}

  static #componentFX = (x, y, z) => AizawaAttractor.#speed * ((z - AizawaAttractor.#b) * x - AizawaAttractor.#d * y); //Change this function

  static #componentFY = (x, y, z) => AizawaAttractor.#speed * (AizawaAttractor.#d * x + (z - AizawaAttractor.#b) * y); //Change this function

  static #componentFZ = (x, y, z) =>
    AizawaAttractor.#speed *
    (AizawaAttractor.#c +
      AizawaAttractor.#a * z -
      (z * z * z) / 3 -
      (x * x + y * y) * (1 + AizawaAttractor.#e * z) +
      AizawaAttractor.#f * z * x * x * x); //Change this function

  // Runge-Kutta method
  static #rungeKutta(x, y, z, h) {
    let k1 = AizawaAttractor.#componentFX(x, y, z);
    let j1 = AizawaAttractor.#componentFY(x, y, z);
    let i1 = AizawaAttractor.#componentFZ(x, y, z);

    let k2 = AizawaAttractor.#componentFX(x + 0.5 * h * k1, y + 0.5 * h * j1, z + 0.5 * h * i1);
    let j2 = AizawaAttractor.#componentFY(x + 0.5 * h * k1, y + 0.5 * h * j1, z + 0.5 * h * i1);
    let i2 = AizawaAttractor.#componentFZ(x + 0.5 * h * k1, y + 0.5 * h * j1, z + 0.5 * h * i1);

    let k3 = AizawaAttractor.#componentFX(x + 0.5 * h * k2, y + 0.5 * h * j2, z + 0.5 * h * i2);
    let j3 = AizawaAttractor.#componentFY(x + 0.5 * h * k2, y + 0.5 * h * j2, z + 0.5 * h * i2);
    let i3 = AizawaAttractor.#componentFZ(x + 0.5 * h * k2, y + 0.5 * h * j2, z + 0.5 * h * i2);
    
    let k4 = AizawaAttractor.#componentFX(x + h * k3, y + h * j3, z + h * i3);
    let j4 = AizawaAttractor.#componentFY(x + h * k3, y + h * j3, z + h * i3);
    let i4 = AizawaAttractor.#componentFZ(x + h * k3, y + h * j3, z + h * i3);
    
    x = x + (h / 6) * (k1 + 2 * k2 + 2 * k3 + k4);
    y = y + (h / 6) * (j1 + 2 * j2 + 2 * j3 + j4);
    z = z + (h / 6) * (i1 + 2 * i2 + 2 * i3 + i4);

    return {
      u: x,
      v: y,
      w: z,
    };
  }

  static update(x, y, z, h) {
    return AizawaAttractor.#rungeKutta(x, y, z, h);
  }

  static generatePoint(x, y, z, h) {
    const nx = AizawaAttractor.#speed * ((z - AizawaAttractor.#b) * x - AizawaAttractor.#d * y);
    const ny = AizawaAttractor.#speed * (AizawaAttractor.#d * x + (z - AizawaAttractor.#b) * y);
    const nz =
      AizawaAttractor.#speed *
      (AizawaAttractor.#c +
        AizawaAttractor.#a * z -
        (z * z * z) / 3 -
        (x * x + y * y) * (1 + AizawaAttractor.#e * z) +
        AizawaAttractor.#f * z * x * x * x);

    let _x = x + h * nx;
    let _y = y + h * ny;
    let _z = z + h * nz;
    
    return { 
        x: _x, 
        y: _y, 
        z: _z 
    };
    return { 
        x: isNaN(_x) ? x : _x, 
        y: isNaN(_y) ? y : _y, 
        z: isNaN(_z) ? z : _z 
    };
  }
}

export class Particle {
  constructor(_x, _y, _z, _h) {
    this.x = _x;
    this.y = _y;
    this.z = _z;
    this.h = _h;
  }
  update(h) {
    this.h = h;
    let tmp = AizawaAttractor.update(this.x, this.y, this.z, this.h);
    this.x = isNaN(tmp.u) ? this.x : tmp.u;
    this.y = isNaN(tmp.v) ? this.y : tmp.v;
    this.z = isNaN(tmp.w) ? this.z : tmp.w;

    //this.time += this.h;
  }
}

export class Lorenz83 {
  static #A = 0.95;
  static #B = 7.91;
  static #F = 4.83;
  static #G = 4.66;
  constructor() {}

  static x(x, y, z) {
    return -1 * (Lorenz83.#A * x) - Math.pow(y, 2) - Math.pow(z, 2) + Lorenz83.#A * Lorenz83.#F;
  }
  static y(x, y, z) {
    return -1 * y + x * y - Lorenz83.#B * x * z + Lorenz83.#G;
  }
  static z(x, y, z) {
    return -1 * z + Lorenz83.#B * x * y + x * z;
  }
}
