/**
 * @format
 */

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Pane } from "tweakpane";
import { MathHelper } from "../../helpers/mathHelper";
import { gsap } from "gsap";
import { Timer } from "three/addons/misc/Timer.js";
import _ from "lodash";
import { AizawaAttractor, Particle } from "./attractors";
import {HeartCurve} from "three/examples/jsm/curves/CurveExtras.js"

/**
 * Base
 */
// Debug
const pane = new Pane({ title: "Params", container: document.querySelector(".tp-container") });
pane.hidden = true;
window.addEventListener("DOMContentLoaded", (event) => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has("debug")) {
    pane.hidden = !JSON.parse(searchParams.get("debug"));
  }
});
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
/**
 * Test cube
 */
const CUBE_PARAMS = {
  cubeVisible: false,
};
const tesseractColorTexture = textureLoader.load("./textures/tesseract/tesseract.webp");
tesseractColorTexture.colorSpace = THREE.SRGBColorSpace;
// tesseractColorTexture.repeat.set(1,1)
// tesseractColorTexture.wrapS = THREE.RepeatWrapping
// tesseractColorTexture.wrapT = THREE.RepeatWrapping
// tesseractColorTexture.magFilter = THREE.NearestFilter
const geometry = new THREE.BufferGeometry();
const vertices = new Float32Array([
  0,
  0,
  0, // v0
]);
geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));

const cube = new THREE.Points(
  geometry,
  new THREE.PointsMaterial({
    map: tesseractColorTexture,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
    //transparent: true,
  })
);
cube.visible = CUBE_PARAMS.cubeVisible;
cube.material.size = 2;
scene.add(cube);

const cubeFolder = pane.addFolder({ title: "Test Cube", index: 0 });
cubeFolder.addBinding(CUBE_PARAMS, "cubeVisible").on("change", (event) => {
  cube.visible = event.value;
  path.line.visible = event.value;
});


//#region Particles
const ParticlesAnimation = {
  FALLING: 0,
  WAVE: 1,
  AIZAWA: 2,
};

const PARTICLES_PARAMS = {
  uuidParticles: null,
  numberOfParticles: 10000,
  particlesColor: "#ffffff",
  paticlesTexture: "1.png",
  particlesMaterialSize: 0.1,
  particlesSizeAttenuation: true,
  particlesAlphaTest: 0.0,
  particlesDepthTest: true,
  particlesDepthWrite: false,
  paticlesBlending: THREE.AdditiveBlending,
  paticlesVertexColors: true,
  particlesWave: 0,
  particlesAnimation: ParticlesAnimation.FALLING,
};

const particles = {
  /**
   * @type {THREE.Points}
   */
  points: null,
  /**
   * @type {THREE.BufferGeometry}
   */
  geometry: null,
  /**
   * @type {THREE.PointsMaterial}
   */
  material: null,
  /**
   * @type {THREE.Texture}
   */
  texture: null,
  
  aizawa: {
    /**
   * @type{Array<Particle>}
   */
    points:new Array(),
  },
};
particles.texture = textureLoader.load("./textures/particles/" + PARTICLES_PARAMS.paticlesTexture);

const path = {
  points: new Array(),
  geometry: null,
  material: null,
  /**
   * @type{THREE.Line}
   */
  line:null
}

const particlesDispose = () => {
  if (particles.points) {
    //particles.aizawa.points = new Array();
    particles.geometry.dispose();
    particles.material.dispose();
    particles.texture.dispose();
    scene.remove(particles.points, particles.aizawa.line);
  }
};

const aizawaLine = (deltaTime) =>{
  let p = {
    x:(Math.random()-0.5)*3.9,
    y:-(Math.random()-0.5)*3.9,
    z:(Math.random()-0.5)*3.9,
  }
  path.points = new Array();
  for(let i=0;i<1000;i++){
    p = AizawaAttractor.generatePoint(p.x,p.y,p.z,deltaTime);
    path.points.push(new THREE.Vector3(p.x,p.y,p.z));
  }

path.geometry = new THREE.BufferGeometry().setFromPoints(path.points);
path.material = new THREE.LineBasicMaterial( { color: 0x0000ff } );

path.line = new THREE.Line(path.geometry, path.material);
path.line.visible = CUBE_PARAMS.cubeVisible
scene.add(path.line);

}

aizawaLine(1)

const particlesGenerator = () => {
  
  particlesDispose();
  //Geometry
  particles.geometry = new THREE.BufferGeometry();
  const vertices = new Float32Array(PARTICLES_PARAMS.numberOfParticles * 3);
  const colors = new Float32Array(PARTICLES_PARAMS.numberOfParticles * 3);
  for (let i = 0; i < PARTICLES_PARAMS.numberOfParticles * 3; i++) {
    vertices[i] = (Math.random() - 0.5) * 10;
    colors[i] = Math.random();
  }
  particles.aizawa.points = new Array();
  for (let i = 0; i < PARTICLES_PARAMS.numberOfParticles; i++) {
    const i3 = i*3;
    particles.aizawa.points.push(new Particle(vertices[i3],vertices[i3+1],vertices[i3+2], 0));
  }
  particles.geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  particles.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  //Material
  particles.material = new THREE.PointsMaterial();
  particles.material.size = PARTICLES_PARAMS.particlesMaterialSize;
  particles.material.sizeAttenuation = PARTICLES_PARAMS.particlesSizeAttenuation;
  particles.material.color = new THREE.Color(PARTICLES_PARAMS.particlesColor);
  particles.material.transparent = true;
  particles.material.alphaMap = particles.texture;
  particles.material.alphaTest = PARTICLES_PARAMS.particlesAlphaTest;
  particles.material.depthTest = PARTICLES_PARAMS.particlesDepthTest;
  particles.material.depthWrite = PARTICLES_PARAMS.particlesDepthWrite;
  particles.material.blending = PARTICLES_PARAMS.paticlesBlending;
  particles.material.vertexColors = PARTICLES_PARAMS.paticlesVertexColors;
  //Points
  particles.points = new THREE.Points(particles.geometry, particles.material);

  scene.add(particles.points);
};

const animateParticles = (elapsedTime, deltaTime) => {
  switch (PARTICLES_PARAMS.particlesAnimation) {
    default:
    case ParticlesAnimation.FALLING: {
      PARTICLES_PARAMS.particlesWave = 0;
      particles.points.rotation.x = elapsedTime * 0.2;
      break;
    }
    case ParticlesAnimation.WAVE: {
      PARTICLES_PARAMS.particlesWave = Math.sin(elapsedTime);
      const yIndex = 1;
      for (let i = 0; i < particles.points.geometry.attributes.position.array.length; i++) {
        const i3 = i * 3;
        const xPostion = particles.points.geometry.attributes.position.array[i3];
        particles.points.geometry.attributes.position.array[i3 + yIndex] = Math.sin(elapsedTime + xPostion);
      }
      particles.geometry.attributes.position.needsUpdate = true;
      break;
    }
    case ParticlesAnimation.AIZAWA: {
      particles.points.rotation.x = 0;
      for (let i = 0; i < particles.points.geometry.attributes.position.array.length; i++) {
        const i3 = i * 3;
        const j3 = Math.floor(i / 3);

        particles.aizawa.points[j3].update(deltaTime * 0.25);

        particles.points.geometry.attributes.position.array[i3 + 0] = particles.aizawa.points[j3].x;
        particles.points.geometry.attributes.position.array[i3 + 1] = particles.aizawa.points[j3].y;
        particles.points.geometry.attributes.position.array[i3 + 2] = particles.aizawa.points[j3].z;
      }
      particles.points.geometry.attributes.position.needsUpdate = true;
      break;
    }
  }
};

particlesGenerator();

const particlesFolder = pane.addFolder({ title: "Particles" });
particlesFolder.addBinding(PARTICLES_PARAMS, "particlesMaterialSize", { min: 0.01, max: 0.5, step: 0.01 }).on("change", (event) => {
  particles.points.material.size = event.value;
});
particlesFolder.addBinding(PARTICLES_PARAMS, "particlesSizeAttenuation").on("change", (event) => {
  particles.points.material.sizeAttenuation = event.value;
});
particlesFolder.addBinding(PARTICLES_PARAMS, "numberOfParticles", { min: 0, max: 100000, step: 100 }).on("change", (event) => {
  particlesGenerator();
});
particlesFolder.addBinding(PARTICLES_PARAMS, "particlesColor").on("change", (event) => {
  particles.points.material.color = new THREE.Color(event.value);
});
particlesFolder.addBinding(PARTICLES_PARAMS, "paticlesVertexColors").on("change", (event) => {
  particles.points.material.vertexColors = event.value;
  particles.points.material.needsUpdate = true;
});

const texturesOptions = new Array(13).fill(0).map((value, index) => {
  return { text: index + 1 + ".png", value: index + 1 + ".png" };
});
particlesFolder
  .addBlade({
    view: "list",
    label: "paticlesTexture",
    options: texturesOptions,
    value: PARTICLES_PARAMS.paticlesTexture,
  })
  .on("change", (event) => {
    PARTICLES_PARAMS.paticlesTexture = event.value;
    particles.texture = textureLoader.load("./textures/particles/" + PARTICLES_PARAMS.paticlesTexture);
    particles.points.material.transparent = true;
    particles.points.material.alphaMap = particles.texture;
    particles.points.material.needsUpdate = true;
  });

particlesFolder.addBinding(PARTICLES_PARAMS, "particlesAlphaTest", { min: 0, max: 1, step: 0.001 }).on("change", (event) => {
  particles.points.material.alphaTest = event.value;
});
particlesFolder.addBinding(PARTICLES_PARAMS, "particlesDepthTest").on("change", (event) => {
  particles.points.material.depthTest = event.value;
});
particlesFolder.addBinding(PARTICLES_PARAMS, "particlesDepthWrite").on("change", (event) => {
  particles.points.material.depthWrite = event.value;
});

particlesFolder
  .addBlade({
    view: "list",
    label: "paticlesTexture",
    options: [
      { text: "No Blending", value: THREE.NoBlending },
      { text: "Normal Blending", value: THREE.NormalBlending },
      { text: "Additive Blending", value: THREE.AdditiveBlending },
      { text: "Subtractive Blending", value: THREE.SubtractiveBlending },
      { text: "Subtractive Blending", value: THREE.SubtractiveBlending },
      { text: "Custom Blending", value: THREE.CustomBlending },
    ],
    value: PARTICLES_PARAMS.paticlesBlending,
  })
  .on("change", (event) => {
    particles.points.material.blending = event.value;
  });

particlesFolder.addBinding(PARTICLES_PARAMS, "particlesWave", {
  view: "graph",
  min: -1,
  max: 1,
  readonly: true,
});
particlesFolder.addBinding(PARTICLES_PARAMS, "particlesWave", {
  bufferSize: 1,
  readonly: true,
});

particlesFolder
  .addBinding(PARTICLES_PARAMS, "particlesAnimation", {
    view: "list",
    label: "particlesAnimation",
    options: [
      { text: "Falling", value: ParticlesAnimation.FALLING },
      { text: "Wave", value: ParticlesAnimation.WAVE },
      { text: "Aizawa", value: ParticlesAnimation.AIZAWA },
    ],
    value: PARTICLES_PARAMS.particlesAnimation,
  })
  .on("change", () => {
    pane.refresh();
    particlesGenerator();
  });

particlesFolder.addButton({ title: "dispose" }).on("click", () => {
  particlesDispose();
});

//#endregion

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 3;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
//controls.maxDistance = 6;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const timer = new Timer();

const tick = () => {
  const elapsedTime = timer.getElapsed();
  timer.update();
  const deltaTime = timer.getDelta();

  animateParticles(elapsedTime, deltaTime);

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();